import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DataCard from "../components/DataCard";

const Home = ({ setUserName }) => {
  const [fetchError, setFetchError] = useState(null);
  const [stockDatas, setStockDatas] = useState([]);
  const [vetementsDatas, setVetementsDatas] = useState([]);
  const [objetsDatas, setObjetsDatas] = useState([]);
  const [billeterieDatas, setBilleterieDatas] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [hidePopup, setHidePopup] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ownerId = params.get("id");

    if (ownerId && stockDatas.length === 0) {
      const API = `https://api.firststock.fr/public/${ownerId}`;

      const formData = new FormData();
      formData.append("ownerId", ownerId);

      fetch(API, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.username) {
            setUserName(data.username);

            const loader = document.querySelector(".loader");
            if (loader) {
              loader.style.display = "none";
            }
          }

          if (Array.isArray(data.items)) {
            const stock = data.items
              .filter((item) => item.category === "SNEAKERS")
              .sort((a, b) =>
                a.title && b.title ? a.title.localeCompare(b.title) : 0
              );
            const vetements = data.items
              .filter((item) => item.category === "VETEMENTS")
              .sort((a, b) =>
                a.title && b.title ? a.title.localeCompare(b.title) : 0
              );
            const objets = data.items
              .filter((item) => item.category === "OBJETS")
              .sort((a, b) =>
                a.title && b.title ? a.title.localeCompare(b.title) : 0
              );
            const billeterieValide = data.items
              .filter((item) => item.category === "BILLETERIE")
              .filter((item) => !isNaN(new Date(item.dateevent).getTime()))
              .sort((a, b) => new Date(a.dateevent) - new Date(b.dateevent));

            const billeterieInvalide = data.items
              .filter((item) => item.category === "BILLETERIE")
              .filter((item) => isNaN(new Date(item.dateevent).getTime()));
            const billeterie = [...billeterieValide, ...billeterieInvalide];

            setStockDatas(stock);
            setVetementsDatas(vetements);
            setObjetsDatas(objets);
            setBilleterieDatas(billeterie);
            setFetchError(null);
          } else {
            setFetchError("Les données ne contiennent pas d'items.");
          }

          if (data.result === "matched") {
            console.log("ID correspondant trouvé !");
          }
        })
        .catch((error) => {
          console.error("Erreur lors de l'envoi des données:", error);
          setFetchError(
            "Une erreur est survenue lors de la récupération des données."
          );
        });
    }
  }, [location.search, stockDatas]);

  useEffect(() => {
    const showTimer = setTimeout(() => {
      setShowPopup(true);
    }, 1000);

    const hideTimer = setTimeout(() => {
      setHidePopup(true);
    }, 5000);

    const removePopupTimer = setTimeout(() => {
      setShowPopup(false);
    }, 5500);

    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
      clearTimeout(removePopupTimer);
    };
  }, []);

  return (
    <div className="page home">
      <div className="loader"></div>
      {fetchError && <p>{fetchError}</p>}

      {/* Display popup */}
      {showPopup && (
        <div
          className={`popup ${hidePopup ? "hide" : ""}`}
          onClick={() =>
            (window.location.href = "https://whop.com/first-stock/")
          }
          style={{ cursor: "pointer" }}
        >
          Ce stock est géré sur l'app FirstStock 🔗
        </div>
      )}

      <div className="datas">
        {/* Display SNEAKERS items */}
        {stockDatas.length > 0 && (
          <div>
            <h2>Sneakers:</h2>
            <div className="data-grid">
              {stockDatas.map((data) => (
                <DataCard key={data.id} data={data} />
              ))}
            </div>
          </div>
        )}

        {/* Display VETEMENTS items */}
        {vetementsDatas.length > 0 && (
          <div>
            <h2>Vetements:</h2>
            <div className="data-grid">
              {vetementsDatas.map((data) => (
                <DataCard key={data.id} data={data} />
              ))}
            </div>
          </div>
        )}

        {/* Display OBJETS items */}
        {objetsDatas.length > 0 && (
          <div>
            <h2>Objets:</h2>
            <div className="data-grid">
              {objetsDatas.map((data) => (
                <DataCard key={data.id} data={data} />
              ))}
            </div>
          </div>
        )}

        {/* Display BILLETERIE items */}
        {billeterieDatas.length > 0 && (
          <div>
            <h2>Billeterie:</h2>
            <div className="data-grid">
              {billeterieDatas.map((data) => (
                <DataCard key={data.id} data={data} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
